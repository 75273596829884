import { useAuthContext } from '../../hooks/useAuthContext'
import PlotData from "./PlotData";
import {useCollectionDropPlot} from "../../hooks/useCollectionDropPlot";
import { useCollectionTrkDrop } from "../../hooks/useCollectionTrkDrop";

// styles
import styles from '../create_track/CreateTrack.module.scss'

export default function TrackerData() {
    const { user } = useAuthContext()
    const { trackerList2, error } = useCollectionDropPlot(
        'customer_state', user.uid
    )
    const { trkList, error2 } = useCollectionTrkDrop(
        'track_info', ["cust_uid", "==", user.uid], ['track_display', 'asc']
    )

    return (
        <div>
            <div className={styles.flex_col}>
                {trkList && <PlotData uid={user.uid} trackers2={trackerList2} trks={trkList}/>}
            </div>
        </div>
    )
}