import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../firebase/config"
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore"


export const useCollectionTrkDrop = (collection_name, _query, _orderBy) => {
    const [trkList, setTrkList] = useState(null)
    const [error2, setError2] = useState(null)

    // if we don't use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call
    const query_1 = useRef(_query).current
    const orderBy_1 = useRef(_orderBy).current

    useEffect(() => {

        let refCol = collection(projectFirestore, collection_name)

        let refCollection = query(refCol, where(...query_1), orderBy(...orderBy_1))

        const unsubscribe = onSnapshot(refCollection,snapshot => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({...doc.data(), id: doc.id})
            });
            let final_results = []
            results.forEach((result) => {
                final_results.push({
                    id: result.track_uid,
                    track_display: result.track_display
                })
            })

            // update state
            setTrkList(final_results)
            setError2(null)
        }, error => {
            console.log(error)
            setError2('could not fetch the data')
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection_name, query_1, orderBy_1])
    return { trkList, error2 }
}