import { useState } from 'react';
import dayjs, {Dayjs} from 'dayjs';
import {useCollectionPlot} from "../../hooks/useCollectionPlot";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {useCollectionTrackId} from "../../hooks/useCollectionTrackId";
import {useFirestore} from "../../hooks/useFirestore";
import {useFirestoreTrkData} from "../../hooks/useFirestoreTrkData";
import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsTooltip, DefaultChartsItemTooltipContent } from '@mui/x-charts/ChartsTooltip';
import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import { Polyline } from "../../components/Polyline";
import down_logo from '../../images/download_logo.png';
import Papa from 'papaparse';

// styles
import styles from '../home/Home.module.scss'
import check_logo from "../../images/check_logo.png";
import trash_logo from "../../images/trash_icon.png";



export default function PlotData({ uid, trackers2, trks }) {
    const [tracker, setTracker] = useState('')
    const [trk, setTrk] = useState('')
    const [open, setOpen] = useState(false);
    const [openLast, setOpenLast] = useState(false);
    const [openFirst, setOpenFirst] = useState(false);
    const [openPast, setOpenPast] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [activeMarker, setActiveMarker] = useState(null);
    const [window, setWindow] = useState(null);
    const [startPosition, setStartPosition] = useState([35.0458, -85.3094]);
    const [dateTimeStart, setDateTimeStart] = useState(dayjs())
    const [dateTimeEnd, setDateTimeEnd] = useState(dayjs())
    const { addDocument, response } = useFirestore('data_queries')
    const { addDocumentTrk, response2 } = useFirestoreTrkData('data_queries_trk')
    const { plotData, error2 } = useCollectionPlot('user_current_query', uid)
    const [temp, setTemp] = useState(null);
    const [humidity, setHumidity] = useState(null);
    const [devLat, setDevLat] = useState(null);
    const [devLong, setDevLong] = useState(null);
    const [battery, setBattery] = useState(null);
    const [tempAlert, setTempAlert] = useState(null);
    const [humidityAlert, setHumidityAlert] = useState(null);
    const [address, setAddress] = useState(null);
    const [arrival, setArrival] = useState(null);
    const [startAddress, setStartAddress] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [trackName, setTrackName] = useState(null);
    const [finalResult, setFinalResult] = useState(null);
    const [finalArrival, setFinalArrival] = useState(null);
    const [eta, setEta] = useState(null);
    const [deviceTime, setDeviceTime] = useState(null);
    const [showData, setShowData] = useState(false);
    const [queryId, setQueryId] = useState(null);
    const [dataTrk, setDataTrk] = useState(false);
    let { trackerId, error } = useCollectionTrackId('user_device_pairings', uid, tracker)


    const handleSubmit = (e) => {
        e.preventDefault()
        addDocument({
            cust_uid: uid,
            query_start_time: dateTimeStart.format(),
            query_end_time: dateTimeEnd.format(),
            tracker_display: tracker,
            tracker_uid: trackerId
        })
        setShowData(true);
        setQueryId(plotData.query_id)
        setDataTrk(false)
    }

    const handleSubmit2 = (e) => {
        e.preventDefault()
        addDocumentTrk({
            cust_uid: uid,
            track_uid: trk
        })
        setShowData(true);
        setQueryId(plotData.query_id)
        setDataTrk(true)
    }

    const handleClick = (marker, lattitude, longitude, temperature, humidity, device_timestamp) => {
        setOpen(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setDeviceTime(device_timestamp)
    };

    const handleClickPast = (marker, lattitude, longitude, temperature, humidity, device_timestamp, start_address, start_time) => {
        setOpenFirst(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setDeviceTime(device_timestamp)
        setStartAddress(start_address)
        setStartTime(start_time)
    };

    const handleClickFuture = (marker, lattitude, longitude, target_address, target_arrival, track_name, final_result, arrival_time) => {
        setOpenLast(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setDevLat(lattitude)
        setDevLong(longitude)
        setAddress(target_address)
        setArrival(target_arrival)
        setTrackName(track_name)
        setFinalResult(final_result)
        setFinalArrival(arrival_time)
    };

    const handleClickAllPast = (marker, lattitude, longitude, temperature, humidity, device_timestamp, humidity_alert, temp_alert) => {
        setOpenPast(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setDeviceTime(device_timestamp)
        setHumidityAlert(humidity_alert)
        setTempAlert(temp_alert)
    };

    const handleDownloadCsv = () => {
        const csvData = Papa.unparse(plotData.time_step_data);

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href
            = url;
        link.setAttribute('download',
            'chart_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className={styles.deviceInfoFlexColumn}>
            <form onSubmit={handleSubmit2} className={styles.deviceInfoContentBox}>
                <div className={styles.deviceInfoFlexRow}>
                    <h2 className={styles.statusTitlePlot}>
                        {/* Title indicating device status. */}
                        Search TRK Name:
                    </h2>
                    {trks && <select
                        className={styles.content_box78}
                        id="trkId"
                        onChange={(e) => setTrk(e.target.value)}>
                        value=trk>
                        <option value="">Select a Tracker</option>
                        {trks.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.track_display}
                            </option>
                        ))}
                    </select>}
                    {plotData && queryId !== plotData.query_id ?
                        <button className={styles.btnCreateTRK}>
                            Get Data
                        </button> : <button className={styles.btnCreateTRK}>
                            Loading...
                        </button>}
                </div>
            </form>
            <form onSubmit={handleSubmit} className={styles.deviceInfoContentBox}>
                <div className={styles.deviceInfoFlexRow}>
                    <h2 className={styles.statusTitlePlot}>
                        {/* Title indicating device status. */}
                        Select Device:
                    </h2>
                    {trackers2 && <select
                        className={styles.content_box78}
                        id="sensorId"
                        onChange={(e) => setTracker(e.target.value)}>
                        value=tracker>
                        <option value="">Select a Tracker</option>
                        {trackers2.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>}
                    <h2 className={styles.statusTitlePlot}>
                        {/* Title indicating device status. */}
                        Query Start Date:
                    </h2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker
                                label="Select Date & Time"
                                value={dateTimeStart}
                                onChange={(e) => setDateTimeStart(e)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <h2 className={styles.statusTitlePlot}>
                        {/* Title indicating device status. */}
                        Query End Date:
                    </h2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                            <DateTimePicker
                                label="Select Date & Time"
                                value={dateTimeEnd}
                                onChange={(e) => setDateTimeEnd(e)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    {plotData && queryId !== plotData.query_id ?
                        <button className={styles.btnCreateTRK}>
                            Get Data
                        </button> : <button className={styles.btnCreateTRK}>
                            Loading...
                        </button>}
                </div>
            </form>
            {plotData && queryId !== plotData.query_id && showData && <div className={styles.deviceInfoContentBox}>
                <div className={styles.deviceInfoFlexRow}>
                    <LineChart
                        xAxis={[{
                            data: plotData.timestamps_text,
                            scaleType: 'point',
                            tickMinStep: 50

                        }]}
                        yAxis={[
                            {id: 'temp', scaleType: 'linear', label: "Temperature (F)"},
                            {id: 'humid', scaleType: 'linear', label: "Humidity (%)"},
                        ]}
                        series={[
                            {
                                yAxisId: 'temp',
                                data: plotData.temps,
                                label: 'Temperature (Deg F)',
                                color: '#3F77BB'
                            },
                            {
                                yAxisId: 'humid',
                                data: plotData.humidity,
                                label: 'Humidity (%)',
                                color: '#529ffb'
                            },
                        ]}
                        leftAxis="temp"
                        rightAxis="humid"
                        width={1200}
                        height={300}
                        tooltip={{trigger: 'axis'}}/>
                </div>
                <img className={styles.lastFlexWrapperImage} src={down_logo} alt="alt text"
                     onClick={handleDownloadCsv}/>
            </div>}
            {plotData && queryId !== plotData.query_id && showData &&
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                    <div className={styles.deviceInfoContentBoxMapOutline}>
                        <div className={styles.deviceInfoContentBoxMap}>
                            {!dataTrk ? <Map gestureHandling={"greedy"} defaultZoom={9}
                                 defaultCenter={{lat: plotData.plot_center[0], lng: plotData.plot_center[1]}}
                                 mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                                {plotData.time_step_data.map((dataPoint) => (
                                    <AdvancedMarker position={{
                                        lat: dataPoint.device_lat_long[0],
                                        lng: dataPoint.device_lat_long[1]
                                    }}
                                                    onClick={() => handleClick(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text)}>
                                        <Pin
                                            background={"#529FFB"}
                                            borderColor={"#3F77BB"}
                                            glyphColor={"#FCFCFC"}
                                        />
                                    </AdvancedMarker>
                                ))}
                                {plotData.first_marker.map((dataPoint) => (
                                    <AdvancedMarker position={{
                                        lat: dataPoint.device_lat_long[0],
                                        lng: dataPoint.device_lat_long[1]
                                    }}
                                                    onClick={() => handleClick(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text)}>
                                        <Pin
                                            background={"#25D82B"}
                                            borderColor={"#3F77BB"}
                                            glyphColor={"#FCFCFC"}
                                        />
                                    </AdvancedMarker>
                                ))}
                                {plotData.last_marker.map((dataPoint) => (
                                    <AdvancedMarker position={{
                                        lat: dataPoint.device_lat_long[0],
                                        lng: dataPoint.device_lat_long[1]
                                    }}
                                                    onClick={() => handleClick(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text)}>
                                        <Pin
                                            background={"#E02323"}
                                            borderColor={"#3F77BB"}
                                            glyphColor={"#FCFCFC"}
                                        />
                                    </AdvancedMarker>
                                ))}
                                {open && (
                                    <div className={styles.deviceInfoContentBoxMapOutline}>
                                        <InfoWindow position={window} onCloseClick={() => setOpen(false)}>
                                            <p>{activeMarker}</p>
                                            <p>Temperature: {temp}</p>
                                            <p>Humidity: {humidity}</p>
                                            <p>Lat: {devLat}</p>
                                            <p>Long: {devLong}</p>
                                            <p>Time: {deviceTime}</p>
                                        </InfoWindow>
                                    </div>
                                )}
                                <Polyline
                                    path={plotData.lat_longs}
                                    // options={{
                                    strokeColor='#3F77BB'
                                    strokeOpacity={1.0}
                                    strokeWeight={4}
                                    geodesic={true}
                                    // }}
                                />
                            </Map> :
                            <Map gestureHandling={"greedy"} defaultZoom={9} defaultCenter={{lat: plotData.plot_center[0], lng: plotData.plot_center[1]}}
                                 mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                                {plotData.time_step_data.map((dataPoint) => (
                                    <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickAllPast(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text, dataPoint.humid_alert, dataPoint.temp_alert)}>
                                        {!dataPoint.alert_point ? <Pin
                                                background={"#529FFB"}
                                                borderColor={"#3F77BB"}
                                                glyphColor={"#FCFCFC"}
                                            /> :
                                            <Pin
                                                background={"#d5be2b"}
                                                borderColor={"#3F77BB"}
                                                glyphColor={"#FCFCFC"}
                                            />
                                        }
                                    </AdvancedMarker>
                                ))}
                                {plotData.first_marker.map((dataPoint) => (
                                    <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickPast(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text, dataPoint.start_address, dataPoint.start_time)}>
                                        <Pin
                                            background={"#25D82B"}
                                            borderColor={"#3F77BB"}
                                            glyphColor={"#FCFCFC"}
                                        />
                                    </AdvancedMarker>
                                ))}
                                {plotData.last_marker.map((dataPoint) => (
                                    <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickFuture(dataPoint.device_name, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.target_address, dataPoint.target_arrival, dataPoint.track_name, dataPoint.final_result, dataPoint.arrival_time)}>
                                        <Pin
                                            background={"#E02323"}
                                            borderColor={"#3F77BB"}
                                            glyphColor={"#FCFCFC"}
                                        />
                                    </AdvancedMarker>
                                ))}
                                {
                                    <Polyline
                                        path={plotData.lat_longs}
                                        // options={{
                                        strokeColor='#3F77BB'
                                        strokeOpacity={1.0}
                                        strokeWeight={4}
                                        geodesic={true}
                                        // }}
                                    />}
                                {openFirst &&
                                    (<div className={styles.deviceInfoContentBoxMapOutline}>
                                        <InfoWindow position={window} onCloseClick={() => setOpenFirst(false)}>
                                            <p>{activeMarker}</p>
                                            <p>Temperature: {temp}</p>
                                            <p>Humidity: {humidity}</p>
                                            <p>Lat: {devLat}</p>
                                            <p>Long: {devLong}</p>
                                            <p>TRK Start Address: {startAddress}</p>
                                            <p>TRK Start Time: {startTime}</p>
                                        </InfoWindow>
                                    </div>)
                                }
                                {openLast &&
                                    (<div className={styles.deviceInfoContentBoxMapOutline}>
                                        <InfoWindow position={window} onCloseClick={() => setOpenLast(false)}>
                                            <p>{activeMarker}</p>
                                            <p>Address: {address}</p>
                                            <p>Lat: {devLat}</p>
                                            <p>Long: {devLong}</p>
                                            <p>TRK Name: {trackName}</p>
                                            <p>TRK Result: {finalResult}</p>
                                            <p>Target Arrival: {arrival}</p>
                                            <p>Actual Arrival: {finalArrival}</p>
                                        </InfoWindow>
                                    </div>)
                                }
                                {openPast &&
                                    (<div className={styles.deviceInfoContentBoxMapOutline}>
                                        <InfoWindow position={window} onCloseClick={() => setOpenPast(false)}>
                                            <p>{activeMarker}</p>
                                            <p>Temperature: {temp}</p>
                                            <p>Humidity: {humidity}</p>
                                            <p>Lat: {devLat}</p>
                                            <p>Long: {devLong}</p>
                                            <p>Time: {deviceTime}</p>
                                            <p>Temperature Alert: {tempAlert}</p>
                                            <p>Humidity Alert: {humidityAlert}</p>
                                        </InfoWindow>
                                    </div>)
                                }
                            </Map>
                            }
                        </div>
                    </div>

                </APIProvider>}
        </div>
    )
}