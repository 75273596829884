import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../firebase/config"
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore"


export const useCollectionNotify = (collection_name, _query, _orderBy, _where) => {
    const [documentsNotify, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    // if we don't use a ref --> infinite loop in useEffect
    // _query is an array and is "different" on every function call
    const query_1 = useRef(_query).current
    const orderBy_1 = useRef(_orderBy).current
    const where_2 = useRef(_where).current

    useEffect(() => {

        let refCol = collection(projectFirestore, collection_name)

        let refCollection = query(refCol, where(...query_1), where(...where_2), orderBy(...orderBy_1))

        const unsubscribe = onSnapshot(refCollection,snapshot => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({...doc.data(), id: doc.id})
            });

            // let final_results = []
            // final_results.push(results)

            // update state
            setDocuments(results)
            setError(null)
        }, error => {
            console.log(error)
            setError('could not fetch the data')
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collection_name, query_1, orderBy_1, where_2])

    return { documentsNotify, error }
}