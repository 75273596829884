import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import './App.css';
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";

import Home from './pages/home/Home'
import Login from './pages/login/Login'
import CreateTrack from './pages/create_track/CreateTrack'
import TrackerData from './pages/tracker_data/TrackerData'
import Navbar from "./components/Navbar";
import UserSettings from "./pages/user_settings/UserSettings";
import Notifications from "./pages/notifications/Notifications";

const loadScriptLibraryList = ["places"]

function App() {
    const { authIsReady, user } = useAuthContext()
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries: loadScriptLibraryList,
    })
    return (
      <div className="App">
          {authIsReady && isLoaded && (
              <BrowserRouter>
                  <Navbar/>
                  <Routes>
                      <Route exact path="/" element={user ? <Home/> : <Navigate to="/login"/>}/>
                      <Route path="/login" element={user ? <Navigate to="/"/> : <Login/>}/>
                      <Route path="/create-track" element={user ? <CreateTrack/> : <Navigate to="/login"/>}/>
                      <Route path="/tracker-data" element={user ? <TrackerData/> : <Navigate to="/login"/>}/>
                      <Route path="/user-settings" element={user ? <UserSettings/> : <Navigate to="/login"/>}/>
                      <Route path="/notifications" element={user ? <Notifications/> : <Navigate to="/login"/>}/>
                  </Routes>
              </BrowserRouter>
          )}
      </div>
  );
}

export default App;
