import { useState } from "react";
import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";
import { useCollectionMap } from "../../hooks/useCollectionMap";
import {useAuthContext} from "../../hooks/useAuthContext";


import styles from './Home.module.scss'
import {Polyline} from "../../components/Polyline";


export default function TrackerMap({tracker_points}) {
    const [startPosition, setStartPosition] = useState([35.0458, -85.3094]);
    const { user } = useAuthContext();
    const [open, setOpen] = useState(false);
    const [openLast, setOpenLast] = useState(false);
    const [openFirst, setOpenFirst] = useState(false);
    const [openPast, setOpenPast] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [activeMarker, setActiveMarker] = useState(null);
    const [window, setWindow] = useState(null);
    const [temp, setTemp] = useState(null);
    const [humidity, setHumidity] = useState(null);
    const [devLat, setDevLat] = useState(null);
    const [devLong, setDevLong] = useState(null);
    const [battery, setBattery] = useState(null);
    const [tempAlert, setTempAlert] = useState(null);
    const [humidityAlert, setHumidityAlert] = useState(null);
    const [address, setAddress] = useState(null);
    const [arrival, setArrival] = useState(null);
    const [startAddress, setStartAddress] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [trackName, setTrackName] = useState(null);
    const [finalResult, setFinalResult] = useState(null);
    const [finalArrival, setFinalArrival] = useState(null);
    const [eta, setEta] = useState(null);
    const [deviceTime, setDeviceTime] = useState(null);
    const { mapCenter, error2 } = useCollectionMap('map_configs_test', user.uid)
    console.log(mapCenter);
    // if (mapCenter) {
    //     setStartPosition(mapCenter)
    // }

    const handleClick = (marker, lattitude, longitude, temperature, humidity, battery_stat, trip_eta, device_timestamp, humidity_alert, temp_alert) => {
        setOpen(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setBattery(battery_stat)
        setEta(trip_eta)
        setDeviceTime(device_timestamp)
        setHumidityAlert(humidity_alert)
        setTempAlert(temp_alert)
    };

    const handleClickPast = (marker, lattitude, longitude, temperature, humidity, device_timestamp, start_address, start_time) => {
        setOpenFirst(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setDeviceTime(device_timestamp)
        setStartAddress(start_address)
        setStartTime(start_time)
    };

    const handleClickFuture = (marker, lattitude, longitude, target_address, target_arrival, track_name, final_result, arrival_time) => {
        setOpenLast(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setDevLat(lattitude)
        setDevLong(longitude)
        setAddress(target_address)
        setArrival(target_arrival)
        setTrackName(track_name)
        setFinalResult(final_result)
        setFinalArrival(arrival_time)
    };

    const handleClickAllPast = (marker, lattitude, longitude, temperature, humidity, device_timestamp, humidity_alert, temp_alert) => {
        setOpenPast(true)
        setActiveMarker(marker)
        setWindow({ lat: lattitude, lng: longitude})
        setTemp(temperature)
        setHumidity(humidity)
        setDevLat(lattitude)
        setDevLong(longitude)
        setDeviceTime(device_timestamp)
        setHumidityAlert(humidity_alert)
        setTempAlert(temp_alert)
    };



    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <div className={styles.deviceInfoContentBoxMapOutline}>
                <div className={styles.deviceInfoContentBoxMap}>
                    {mapCenter && !mapCenter.click && (<Map gestureHandling={"greedy"} defaultZoom={10} defaultCenter={{ lat: mapCenter.center[0], lng: mapCenter.center[1]}} mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                        {tracker_points.map((tracker) => (
                        <AdvancedMarker position={{ lat: tracker.device_lat_long[0], lng: tracker.device_lat_long[1]}} onClick={() => handleClick(tracker.tracker_display, tracker.device_lat_long[0], tracker.device_lat_long[1], tracker.temp, tracker.humidity, tracker.battery_status, tracker.eta_text, tracker.device_timestamp_text, tracker.humid_alert, tracker.temp_alert)}>
                            {!tracker.alert_point ? <Pin
                                background={"#529FFB"}
                                borderColor={"#3F77BB"}
                                glyphColor={"#25D82B"}
                            /> :
                                <Pin
                                    background={"#529FFB"}
                                    borderColor={"#3F77BB"}
                                    glyphColor={"#d5be2b"}
                                />
                            }
                        </AdvancedMarker>
                        ))}
                        {open && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpen(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>Battery: {battery}</p>
                                    <p>Time: {deviceTime}</p>
                                    <p>ETA: {eta}</p>
                                    <p>Temperature Alert: {tempAlert}</p>
                                    <p>Humidity Alert: {humidityAlert}</p>
                                </InfoWindow>
                            </div>
                        )}
                        {mapCenter.on_track && mapCenter.first_click && (mapCenter.time_step_data.map((dataPoint) => (
                            <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickAllPast(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text, dataPoint.humid_alert, dataPoint.temp_alert)}>
                                {!dataPoint.alert_point ? <Pin
                                    background={"#529FFB"}
                                    borderColor={"#3F77BB"}
                                    glyphColor={"#FCFCFC"}
                                /> :
                                    <Pin
                                        background={"#d5be2b"}
                                        borderColor={"#3F77BB"}
                                        glyphColor={"#FCFCFC"}
                                    />
                                }
                            </AdvancedMarker>
                        )))}
                        {mapCenter.on_track && mapCenter.first_click && (mapCenter.first_marker.map((dataPoint) => (
                            <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickPast(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text, dataPoint.start_address, dataPoint.start_time)}>
                                <Pin
                                    background={"#25D82B"}
                                    borderColor={"#3F77BB"}
                                    glyphColor={"#FCFCFC"}
                                />
                            </AdvancedMarker>
                        )))}
                        {mapCenter.on_track && mapCenter.first_click && (mapCenter.last_marker.map((dataPoint) => (
                            <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickFuture(dataPoint.device_name, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.target_address, dataPoint.target_arrival, dataPoint.track_name, dataPoint.final_result, dataPoint.arrival_time)}>
                                <Pin
                                    background={"#E02323"}
                                    borderColor={"#3F77BB"}
                                    glyphColor={"#FCFCFC"}
                                />
                            </AdvancedMarker>
                        )))}
                        {mapCenter.on_track && mapCenter.first_click &&
                            (<Polyline
                            path={mapCenter.lat_longs}
                            // options={{
                            strokeColor='#3F77BB'
                            strokeOpacity={1.0}
                            strokeWeight={4}
                            geodesic={true}
                            // }}
                        />)}
                        {mapCenter.on_track && openFirst && mapCenter.first_click && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpenFirst(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>TRK Start Address: {startAddress}</p>
                                    <p>TRK Start Time: {startTime}</p>
                                </InfoWindow>
                            </div>
                        )}
                        {mapCenter.on_track && openLast && mapCenter.first_click && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpenLast(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Address: {address}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>TRK Name: {trackName}</p>
                                    <p>TRK Result: {finalResult}</p>
                                    <p>Target Arrival: {arrival}</p>
                                    <p>Actual Arrival: {finalArrival}</p>
                                </InfoWindow>
                            </div>
                        )}
                        {mapCenter.on_track && openPast && mapCenter.first_click && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpenPast(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>Time: {deviceTime}</p>
                                    <p>Temperature Alert: {tempAlert}</p>
                                    <p>Humidity Alert: {humidityAlert}</p>
                                </InfoWindow>
                            </div>
                        )}
                    </Map>)}
                    {mapCenter && mapCenter.click && (<Map gestureHandling={"greedy"} defaultZoom={10} defaultCenter={{ lat: mapCenter.center[0], lng: mapCenter.center[1]}} mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                        {tracker_points.map((tracker) => (
                            <AdvancedMarker position={{ lat: tracker.device_lat_long[0], lng: tracker.device_lat_long[1]}} onClick={() => handleClick(tracker.tracker_display, tracker.device_lat_long[0], tracker.device_lat_long[1], tracker.temp, tracker.humidity, tracker.battery_status, tracker.eta_text, tracker.device_timestamp_text, tracker.humid_alert, tracker.temp_alert)}>
                                {!tracker.alert_point ? <Pin
                                        background={"#529FFB"}
                                        borderColor={"#3F77BB"}
                                        glyphColor={"#25D82B"}
                                    /> :
                                    <Pin
                                        background={"#529FFB"}
                                        borderColor={"#3F77BB"}
                                        glyphColor={"#d5be2b"}
                                    />
                                }
                            </AdvancedMarker>
                        ))}
                        {open && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpen(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>Battery: {battery}</p>
                                    <p>Time: {deviceTime}</p>
                                    <p>ETA: {eta}</p>
                                    <p>Temperature Alert: {tempAlert}</p>
                                    <p>Humidity Alert: {humidityAlert}</p>
                                </InfoWindow>
                            </div>
                        )}
                        {mapCenter.on_track && mapCenter.first_click && (mapCenter.time_step_data.map((dataPoint) => (
                            <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickAllPast(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text, dataPoint.humid_alert, dataPoint.temp_alert)}>
                                {!dataPoint.alert_point ? <Pin
                                        background={"#529FFB"}
                                        borderColor={"#3F77BB"}
                                        glyphColor={"#FCFCFC"}
                                    /> :
                                    <Pin
                                        background={"#d5be2b"}
                                        borderColor={"#3F77BB"}
                                        glyphColor={"#FCFCFC"}
                                    />
                                }
                            </AdvancedMarker>
                        )))}
                        {mapCenter.on_track && mapCenter.first_click && (mapCenter.first_marker.map((dataPoint) => (
                            <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickPast(dataPoint.tracker_display, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.temp, dataPoint.humidity, dataPoint.device_timestamp_text, dataPoint.start_address, dataPoint.start_time)}>
                                <Pin
                                    background={"#25D82B"}
                                    borderColor={"#3F77BB"}
                                    glyphColor={"#FCFCFC"}
                                />
                            </AdvancedMarker>
                        )))}
                        {mapCenter.on_track && mapCenter.first_click && (mapCenter.last_marker.map((dataPoint) => (
                            <AdvancedMarker position={{ lat: dataPoint.device_lat_long[0], lng: dataPoint.device_lat_long[1]}} onClick={() => handleClickFuture(dataPoint.device_name, dataPoint.device_lat_long[0], dataPoint.device_lat_long[1], dataPoint.target_address, dataPoint.target_arrival, dataPoint.track_name, dataPoint.final_result, dataPoint.arrival_time)}>
                                <Pin
                                    background={"#E02323"}
                                    borderColor={"#3F77BB"}
                                    glyphColor={"#FCFCFC"}
                                />
                            </AdvancedMarker>
                        )))}
                        {mapCenter.on_track && mapCenter.first_click &&
                            (<Polyline
                                path={mapCenter.lat_longs}
                                // options={{
                                strokeColor='#3F77BB'
                                strokeOpacity={1.0}
                                strokeWeight={4}
                                geodesic={true}
                                // }}
                            />)}
                        {mapCenter.on_track && openFirst && mapCenter.first_click && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpenFirst(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>TRK Start Address: {startAddress}</p>
                                    <p>TRK Start Time: {startTime}</p>
                                </InfoWindow>
                            </div>
                        )}
                        {mapCenter.on_track && openLast && mapCenter.first_click && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpenLast(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Address: {address}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>TRK Name: {trackName}</p>
                                    <p>TRK Result: {finalResult}</p>
                                    <p>Target Arrival: {arrival}</p>
                                    <p>Actual Arrival: {finalArrival}</p>
                                </InfoWindow>
                            </div>
                        )}
                        {mapCenter.on_track && openPast && mapCenter.first_click && (
                            <div className={styles.deviceInfoContentBoxMapOutline}>
                                <InfoWindow position={window} onCloseClick={() => setOpenPast(false)}>
                                    <p>{activeMarker}</p>
                                    <p>Temperature: {temp}</p>
                                    <p>Humidity: {humidity}</p>
                                    <p>Lat: {devLat}</p>
                                    <p>Long: {devLong}</p>
                                    <p>Time: {deviceTime}</p>
                                    <p>Temperature Alert: {tempAlert}</p>
                                    <p>Humidity Alert: {humidityAlert}</p>
                                </InfoWindow>
                            </div>
                        )}
                    </Map>)}
                </div>
            </div>

        </APIProvider>

    )
}
