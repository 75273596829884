import { useAuthContext } from '../../hooks/useAuthContext'
import {useCollectionNotify} from "../../hooks/useCollectionNotify"


// styles
import styles from './Notifications.module.scss'
import NotificationsList from "./NotificationsList";

export default function Notifications() {
    const { user } = useAuthContext()
    const { documentsNotify, error } = useCollectionNotify(
         'notifications', ["cust_uid", "==", user.uid], ['alert_time', 'asc'], ["active_alert", "==", true]
    )
    console.log(documentsNotify)

    return (
        // <div>
        //     <p>BridgTRK Future Dashboard</p>
        // </div>
        <div>
            <div className={styles.flex_col}>
                {documentsNotify && <NotificationsList notifications={documentsNotify}/>}
            </div>
        </div>
    )
}