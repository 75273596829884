import { useFirestoreButtonNotify } from "../../hooks/useFirestoreButtonNotify";

import check_logo from '../../images/check_logo.png';
import { useAuthContext } from "../../hooks/useAuthContext";

// styles
import styles from './Notifications.module.scss'

export default function NotificationsList({ notifications }) {
    const { user } = useAuthContext()
    const { updateDocumentNotify, response2 } = useFirestoreButtonNotify("notifications")

    const handleClick = (notify_id) => {
        updateDocumentNotify(notify_id)
    };

    return (
        <div className={styles.deviceInfoFlexColumn}>
            {/* Flex column for detailed device information. */}
            {notifications.map((notification) => (
                <div className={notification.alert_message === "TRK Completed" ? styles.deviceInfoContentBoxGreen : styles.deviceInfoContentBox}>
                    {/* Box for device information details. */}

                    <div className={styles.deviceInfoFlexRow}>
                        {/* Flex row to organize device info items. */}

                        <h2 className={styles.trackerIdText}>
                            {/* Text showing the unique tracker ID. */}
                            <span className={notification.alert_message === "TRK Completed" ? styles.trackerIdText : styles.trackerIdTextRed}>{notification.alert_message}</span>
                        </h2>
                        <h2 className={styles.statusTitle}>
                            {/* Title indicating device status. */}
                            Time:
                        </h2>
                        <h2 className={styles.trackerIdText}>
                            {/* Text showing the unique tracker ID. */}
                            <span className={styles.trackerIdText}>{notification.alert_time}</span>
                        </h2>
                        <h2 className={styles.statusTitle}>
                            {/* Title indicating device status. */}
                            Location:
                        </h2>
                        <h2 className={styles.trackerIdText}>
                            {/* Text showing the unique tracker ID. */}
                            <span className={styles.trackerIdText}>{notification.alert_location}</span>
                        </h2>
                        <h2 className={styles.statusTitle}>
                            {/* Title indicating device status. */}
                            TRK Name:
                        </h2>
                        <h2 className={styles.trackerIdText}>
                            {/* Text showing the unique tracker ID. */}
                            <span className={styles.trackerIdText}>{notification.trk_display}</span>
                        </h2>
                    </div>
                    <img className={styles.lastFlexWrapperImage} src={check_logo} alt="alt text" onClick={() => {handleClick(notification.id)}}/>
                </div>
            ))}
        </div>
    )
}